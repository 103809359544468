import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, output, SimpleChanges, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { InputSwitch, InputSwitchModule } from 'primeng/inputswitch';

type SlideToggleSize = 'small' | 'normal' | 'large';

@Component({
	selector: 'sl-slide-toggle',
	templateUrl: './slide-toggle.component.html',
	styleUrl: './slide-toggle.component.scss',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SlideToggleComponent,
		multi: true
	}],
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		InputSwitchModule,
		MatTooltipModule,
		RouterModule
	]
})
export class SlideToggleComponent implements ControlValueAccessor, OnChanges {
	@Input() readonly label: string = null;
	@Input() readonly description: string = null;
	@Input() readonly link: string = null;
	@Input() readonly linkTooltip: string = null;
	@Input() readonly checked = false;
	@Input() readonly size: SlideToggleSize = 'normal';
	@Input() readonly disabled: boolean;

	readonly toggled = output<{ originalEvent: MouseEvent; checked: boolean }>();
	readonly linkClick = output<void>();

	readonly inputSwitch = viewChild.required<InputSwitch>(InputSwitch);

	onChangeFunction: (fn: any) => void;
	onTouchedFunction: () => void;

	@HostBinding('class') get hostClasses(): object {
		const size = this.size || 'normal';
		return {
			'sl-slide-toggle': true,
			['sl-slide-toggle--' + size]: true,
			['sl-slide-toggle--no-content']: !this.label && !this.description
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.checked) {
			this.writeValue(this.checked);
		}

		if (changes.disabled) {
			this.setDisabledState(this.disabled);
		}
	}

	change(options: { originalEvent: MouseEvent; checked: boolean }): void {
		this.toggled.emit(options);
	}

	writeValue(checked: boolean): void {
		this.inputSwitch().writeValue(checked);
	}

	setDisabledState(isDisabled: boolean): void {
		this.inputSwitch().setDisabledState(isDisabled);
	}

	registerOnChange(fn: () => void): void {
		this.onChangeFunction = fn;
		this.inputSwitch().registerOnChange(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFunction = fn;
		this.inputSwitch().registerOnTouched(fn);
	}
}
